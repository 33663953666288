
.ooo{

    
background-color: #0c92d4;


  

}
.fixed-nav{
    margin-top: 139px;
    background: #f2f3f5;
    top: 137px;
    width: 100%;
    z-index: 42;
    /* color: #14181f; */
    /* position: fixed; */

}



.medicine1 {
    
    

    background-color: #f7f7f7;
    height: 616px;
    display: flex;
    align-items: center;
    gap: 127px;
    font-family: 'Poppins', sans-serif;
    border-bottom: solid #ae492d;
    
   

    }
    .medicine1::after{
        background: url("../../img//back123.png");
    
        content: "";
        top: 502px;
        bottom: -193px;
        left: 1013px;
        right: 4px;
        position: absolute;
        z-index: 1;
        }
.medicine2 >h1{
    margin-top: -72px;
    font-size: 2.5rem;
    font-family: 'Poppins', sans-serif;
    color: #d40c8d;
}
  
  
  
    

.medicine2 >h4{
    margin-top: 20px;
    font-size: 1.4rem;
    color: black;
    font-family: 'Poppins', sans-serif;
}
.iimgae{
            margin-top: 62px;
            margin-bottom: 36px;
            width: 39%;
            max-height: 28rem;
            border-radius: 3%;
            margin-right: 0px;
            margin-left: 95px;
            opacity: 1;
            background-blend-mode: soft-light;
            border-bottom: 2px solid #0c92d4;
            border-left: 2px solid #0c92d4;
            border-right: 2px solid #0c92d4;
            border-top: 2px solid #0c92d4;
}
.medicine2{
    
    margin-top: 1px;
    width: 68%;
}
.medicine2 >h2{
    
    font-family: 'Poppins', sans-serif;
    font-weight: lighter;
    font-size: 1rem;
    margin-top: 6px;
  
}
span {
    display: table-cell;
    padding: 0px 0px 0px 0px;
    white-space: nowrap;
    color: white;
}
.icons-d{
  

}
.icons-class{
    height: 46px;
    margin-top: 13px;
    display: flex;
   background-color: white;
   justify-content: center;
   

    gap: 34px;
}
.icons-d:hover{
    background-color: white;
   
}
.icons-m{
    margin-top:9px
}

@media  (max-width: 768px){
    .medicine1 {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        width: 100%;
        height: auto;
        
    }
    .medicine2{
        display: flex;
        flex-direction: column;
        align-items: baseline;
            width: 74%;
        height: auto;
    }
    img{
        display: flex;
        width: 100%;
        height: auto;

    }

    }
    @media screen and (max-width: 820px){
        .medicine1 {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            width: 100%;
            height: auto;
            
        }
        .medicine2{
            display: flex;
            flex-direction: column;
            align-items: baseline;
                width: 74%;
                
            height: auto;
        }
        img{
            display: flex;
            width: 100%;
            height: auto;
    
        }
     }
     
   