* {
    margin: 0;
    padding: 0;
  
}
.img-fot{
   
   background-color: #e1e1e1;

}
.id{
    
    display: flex;
    flex-direction: row;
   
    align-items: baseline;

    gap: 225px;
    height: 45vh;
}
.leftFooter{
    margin-top: 28px;
    margin-left: 36px;
    display: flex;
    flex-direction: row;
    width: 24%;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: space-between;

}
.leftFooter>h1{
    margin-left: 10px;
color: #126098;

   
}
.leftFooter >p{
    font-family: 'Poppins', sans-serif;
    font-weight: lighter;
    font-size: clamp(0.9rem, 0.1vw, 2rem);
    color: black;
    margin-top: 6px;
  
       
    }

.midFooter{
    margin-top: 1px;
    width: 24%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
   
}
.midFooter>h1{
    color: #126098;
}
.midFooter .button-f>a {
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    gap: 34px;
    justify-content: center;
align-items: center;
background-color: #e1e1e1;
color: black;
padding: 1rem 2rem;
}
.midFooter .button-f> :hover{
    color: #b15035;
}


.last4{
    margin-top: 1px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    color: white;

}

.rightFooter{
    display: flex;
    flex-direction: column;
    color: aliceblue;
}
.rightFooter>h4{
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    color: #126098;
}
.rightFooter>a{
   
    color: black;
    margin-top: 15px;
    font-size: 15px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}
.rightFooter>a:hover{
    color: #b15035;
}
.rightFooter >span{
    word-spacing: 1px;
}



.copywrite>h6{
    padding: 0.3rem;
    font-family: 'Sofia Sans Semi Condensed', sans-serif;
    font-size: clamp(0.9rem, 0.1vw, 2rem);
    text-align: center;
    color: #e1e1e1;
    background-color: rgb(174 73 45);
}

@media  (max-width: 768px){
    .img-fot {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        
    }
    .id{
        display: flex;
        height: 97vh;
        flex-direction: column;
        flex-wrap: wrap;
        gap:1px;

    }
    .leftFooter{
        display: flex;
        width: 90%;
        height: auto;

    }
    .midFooter{
        display: flex;
        flex-direction: column;
        align-items: baseline;
        width: 89%;
        height: auto;

    }
    .last4{
   
display: flex;
flex-direction: column;
width: 90%;
  }
  .rightFooter{
    display: flex;
    align-items: flex-start;
    align-content: stretch;
    justify-content: center;
    flex-wrap: wrap;
    width: 90%;

  }
  .rightFooter>a{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
  }

    }
    @media  (min-width: 820px){
        .img-fot {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
            
            
        }
        .id{
            display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 129px;
    height: 49vh;
        }
       
        }




 .hover-underline-animation {
   display: inline-block;
   position: relative;
   color: white;
   font-family: 'Sofia Sans Semi Condensed', sans-serif;

 }
 
 .hover-underline-animation:after {
   content: '';
   position: absolute;
   width: 100%;
   transform: scaleX(0);
   height: 2px;
   bottom: 0;
   left: 0;
   background-color: 
 #0087ca;
   transform-origin: bottom right;
   transition: transform 0.25s ease-out;
 }
 
 .hover-underline-animation:hover:after {
   transform: scaleX(1);
   transform-origin: bottom left;
 }
 .maps iframe {
    height: 75vh;
    width: 45%;
    margin-left: 50px;
    border-radius: 50px !important
 }
 .maps{
    border: #0087ca;
    display: flex;
    gap: 71px;
    padding: 23px;
    margin-bottom: 31px;
   
   
   

 }
 .stringsss{
    text-align: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
 }
 .stringsss span{
    
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    color: #126098;
    margin-left: 10px;
 
    
 }
 .maps>a{
     font-size: clamp(1rem, 0.1vw, 2rem);
     color: #e1e1e1;
     text-decoration: none;
 }
 a{
    text-decoration: none;
 }
 .maps>a:hover{
     color: #126098;
 }
 .location-info>p{
    margin-top: 9px;
 }
.location-info{
   
    gap: 1px;
    font-family: 'Poppins', sans-serif;
 }
.location-info >h1{
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    color: #126098;
    margin-top: 50px;
 }
 .location-info>a{
    color: black;
 }
 .location-info>a:hover{
    color: #b15035;
 }
 .navbarslick{
  
    filter: blur(0.1px);
    -webkit-filter: blur(0.1px);
    
 }
 @media  (max-width: 768px){
    .maps{
        display: flex;
        flex-direction: column;

    }
    .maps>iframe{
        width: 100%;
        height: auto;
        margin-left: 0px;
        border-radius: 0px;
        margin-top: 99px;
}
 
    .img-png{
        display: none;
    }
    .slick-nav{
        display: none;
        
    }.icons{
       position: absolute;
       width: 0%;
       height: 10vh;
      
        gap: 0px;
      
    }
    .link-s {

       margin-left: 1px; 
         margin-top: 1px; 
         position: absolute;

    }
    .link-s >a{
        display: none;
        background-color: #126098;
        /* margin-left: 382px; */
        /* margin-top: 153px; */
        gap: 0px;
  
    }
 
    .img-png{
        display:none;
    }
 }
 .icons{
    display: flex;
    HEIGHT: 46VH;
    flex-direction: row;
  
    background-color: #126098;
 }

.link-s{
    display: flex;
    background-color: #126098;
margin-left: 382px;
margin-top: 153px;
gap: 18px
}

.link-s>a{
    color: #e1e1e1;
}
.link-s>a:hover{
    color: #b15035;
}
.img-png{
    background-color: #126098;
}

.img-png>img{
    width: 27%;
    height: auto;
    margin-left: 550px;
    margin-top: 182px;
}