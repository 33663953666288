* {
    margin: 0;
    padding: 0;
    
  }
  
  .main {
    width: 100%;
    height: auto;
    position: relative;
  }
  
  video {
    max-width: 100%;
    max-height: auto;
    margin-top: 78px;
   
    object-fit: cover;
   
    
  }
  .overlay >h1{
    top: 42%;
    text-align: center;
    font-family: 'Abel', sans-serif;
    background: -webkit-linear-gradient(top, #1e5799 0%, #207ccA 36%, #2989D8 50%, #89C2FF 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    position: absolute;
    font-size: clamp(0.5rem, 4vw, 7rem);
    margin-left: 259px;

  
  
  }
  
  
  .header {
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 3vmax;

    width: 20vmax;
    padding: 1vmax;
    margin: 1vmax auto;
    background: -webkit-linear-gradient(top, #1e5799 0%, #207ccA 36%, #2989D8 50%, #89C2FF 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip:text;
  }

  @media screen and (max-width: 768px){
    .overlay >h1{
      margin-left: 82px;
    }
  }