
.upper{
    background-image: url("../../img/coverz.png");
  
    background-repeat: round;
    background-attachment: fixed;
    background-position: cover;
    background-blend-mode: soft-light;
    background-color: rgba(255, 255, 255, 0.664);
}
    
  
    

tbody{
    background-color: #dddddd;
}
.content-table {

    margin: 64px 138px;
    font-size: clamp(0.9rem, 1vw, 7rem);
    min-width: 370px;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    font-family: 'Poppins', sans-serif;
  margin-top: 151px;
    
 
}

.content-table thead tr {
    font-family: 'Poppins', sans-serif;
    background: -webkit-linear-gradient(top, #ae492d 12%, #126098 4%, #126098 86%, #c78877 56%);
    color: #ffffff;
    text-align: left;
    font-weight: bold;
}
td{
color: #268acc;
}
button{
    text-decoration: none;
    max-width: auto;
    background-color: #2fa2db;
    color: rgb(255 255 255);
    padding: 1.4rem 2.4rem;
    border: none;
    position: inherit;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;

    }
   


button:hover{

 
  
      transform: scale(0.96);
}
.content-table th,
.content-table td {
    padding: 12px 15px;
}

.content-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}
h1{
    display: flex;
    flex-direction: row;
}
.strings{
    color: #e62b2b;
    font-family: 'Poppins', sans-serif;
    height: 10vh;
    font-size: larger;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #126098;
    border-bottom: 6px solid brown;
    position: relative;
}


@media screen and (max-width: 768px) {
    button{
        display: block;
        width: auto;
        float: none;
        font-size: clamp(0.5rem, 0vw, 0rem);
        padding: 0.1rem 0.1rem;
    }
    .content-table{
        height: 100%;
        width: 10%;
        margin-top: 35px;
        margin: auto;
        font-size: clamp(0.5rem, 0vw, 0rem);
    }
}