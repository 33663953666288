.home .posts {

    padding: 21px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 150px;
    background-repeat: no-repeat;
 background-attachment: fixed;
 background-position: center center;
 background-size: cover;
}
.home .posts .post {
    display: flex;
    
}
.home .posts .post:nth-child(2n + 1) {
    flex-direction: row-reverse;
}
.home .posts .post .img {
    flex: 2;
    position: relative;
    z-index: 1;
    

}
img:hover{

}
.home .posts .post .img::after {
    content: "";
    width: 71%;
    height: 98%;
    border-radius: 15px;
    background-color: #2fa2db;
    position: absolute;
    top: 20px;
    left: 37px;
    right: 100px;
    z-index: -1;
}
.img img {
    width: 72%;
    max-height: 203px;
    margin-left: 60px;
    object-fit: cover;
    border-radius: 19px;
}
.home .posts .post .content {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0px;
    margin-bottom: 0px;
}
.content >h1 {
    top: 1px;
    margin-top: -6px;
    font-size: 36px;
    margin-left: 73px;
    color: #008dd2;
    margin-bottom: 0px;
   
}
.content >p {
    margin-top: 0px;
    font-size: 16px;
    padding: 0;
    color: #000000c7;
    margin-bottom: -6px;
    margin-left: 73px;
    
}
 .home button  {
    width: max-content;
    margin-bottom: 0px;
    margin-top: 16px;
    margin-left: 73px;
    top: 1px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: white;
    border: 1px solid #ff0cbf;
    color: #ff0cbf;
}
.home .posts .post .content button:hover {
    border: 1px solid white;
    color: white;
    background-color: #ff0cbf;
}
@media screen and (max-width: 768px) {

    home .posts .post .img::after {
   display: flex;
   flex-direction: column;
   width: auto;
}
.home .posts .post .img::after{
    content: "";
    width: 71%;
    height: 21%;
    border-radius: 15px;
    background-color: #2fa2db;
    position: absolute;
    top: 20px;
    left: 37px;
    right: 100px;
    z-index: -1;
}
.content >h1{
    top: 1px;
    margin-top: 0px;
    font-size: 24px;
    margin-left: 18px;
    color: #008dd2;
    margin-bottom: 0px;
}

.home .posts{
    padding: 0px;
    margin-top: 2px;
    display: flex;
    flex-direction: column;
    gap: 33px;


}
.content >p {
    margin-top: 0px;
    font-size: 14px;
    padding: 2px;
    color: #000000c7;
    margin-bottom: 0px;
    margin-left: 17px;
}

.content > button>a {
    width: max-content;
    margin-bottom: 0px;
    margin-top: 16px;
    margin-left: 22px;
    top: 1px;
    padding: 3px 9px;
    border: none;
    cursor: pointer;
    background-color: white;
    border: 1px solid #ff0cbf;
    color: #ff0cbf;
}

}
