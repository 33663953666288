
*{
  margin: 0;
  padding: 0;
}
.prediabetes1{


   display: flex;
  
 
   filter: brightness(112%);
   border-radius: 39px;
   margin-right: 66px;
   margin-left: 1px;
   object-fit: cover;

   

 
 
  
 }
 .text1> h2{
   font-family: 'Lato', sans-serif;
    color:#2fa2db;
    font-size: clamp(1.4rem, 3vw, 7rem);

 }
 .text1> p{
    font-family: 'Andika', sans-serif;
     padding: 3px;
     color:#414141;
     font-size: 16px;
     font-size: clamp(0.6rem, 1.3vw, 2rem);
    
  }
  h3{
   font-family: 'Lato', sans-serif;
    color: #2fa2db;
    margin-top: 24px;
    font-size: clamp(1.1rem, 1.9vw, 2rem);
    
  }

  .prediabetes1 .img1>img{
  
   margin-top: 9px;
   width: 20vw;
   height: 90VH;
   filter: brightness(112%);
   border-radius: 39px;
   margin-right: 66px;
   margin-left: 21px;
   margin-top: 188px;

 


  }
  .prediabetes1 .img1:hover::after {
    content: "";
    width: 32%;
    height: 68%;
    border-radius: 30px;
    background-color: #2fa2db;
    position: absolute;
    top: 94px;
    left: 823px;
    right: 70px;
    margin-top: 76px;
    /* bottom: -8px; */
    z-index: -1;
 }


 .text1{
    
   border-radius: 15px;
   margin-left: 100px;
   margin-right: 99px;
   margin-bottom: 70px;
   margin-top: 187px;
  
 }
 .spacing{
   word-spacing: 74px;
 }
 .spacing1{
   word-spacing: 86px;
 }
 .spacing2{
   word-spacing: 112px;
 }
 .spacing3{
   word-spacing: 100px;
 }

 @media screen and (max-width: 800px) {

  .prediabetes1 {
    margin-top: 1px;
   display: flex;
    flex-direction: column;
   

  }
  .text1{
   
    border-radius: 0px;
    margin-left: 16px;
    margin-right: 1px;
    margin-bottom: -102px;
   
 }
.img1 img{
  min-width: 36vw;
    max-height: 30VH;;
  display:block;margin:0 auto;


}

     
}


@media only screen and (min-width: 992px) {
  .prediabetes1{

        display: flex;
   
        filter: brightness(112%);
        border-radius: 39px;
        margin-right: 66px;
        margin-left: 1px;
      }
      .img1 img{
        min-width: 32vw;
        max-height: 85VH;
        margin-left: 1px;
        margin-right: 1px;

}

}
/*se*/
@media  (max-width: 667px) {
  .prediabetes1{
 
        display: flex;
     
        filter: brightness(112%);
        border-radius: 39px;
        margin-right: 66px;
        margin-left: 1px;
      }
      .img1 img{
        display:block;margin:0 auto;

}
.slider-bottom1 {
  margin-top: 0px;
 
}
}

/*xr*/


