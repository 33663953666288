
*{
  margin: 0;
  padding: 0;

  font-family: 'Poppins', sans-serif;

 
}
.wrapper{
  background: #f2f3f5;
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  z-index: 999;
  color: #14181f;
 position: fixed;


  width: 100%;
  border-bottom: 4px solid #ae492d;
  z-index: 999;
}
.wrapper nav{
  position: relative;
  display: flex;
  margin: 2px auto;
  height: 76px;
  align-items: center;
  justify-content: space-between;
  color: #14181f;
}
.wrapper a{
  text-decoration: none;
}
nav .content{
  color: #14181f;
  display: flex;
  align-items: center;
  gap: 411px;
}
nav .content .links{
  margin-left: 80px;
  display: flex;
  color: #14181f;


}
.content .logo>img{
  width: 92px;
  height: 51px;
  margin-left: 35px;
}

.content .logo a{
  color: #14181f;

  font-size: 30px;
  font-weight: 600;
}
.content .links li{
  color: white;
  list-style: none;
  line-height: 70px;
}
.content .links li a
{
  color: black;
  font-size: 18px;
  font-weight: 500;
  padding: 9px 17px;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.content .links li label{
  display: none;
  color: #14181f;
}
.content .links li a:hover,
.content .links li label :active{
  background: #126098;
  color: white;
}
.wrapper .search-icon,
.wrapper .menu-icon{
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  line-height: 70px;
  width: 70px;
  text-align: center;
}
.wrapper .menu-icon{
  display: none;
}
.wrapper #show-search:checked ~ .search-icon i::before{
  content: "\f00d";
}
.wrapper .search-box{
  position: absolute;
  height: 100%;
  max-width: calc(100% - 50px);
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
}
.wrapper #show-search:checked ~ .search-box{
  opacity: 1;
  pointer-events: auto;
}
.search-box input{
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 17px;
  color: #fff;

  padding: 0 100px 0 15px;
}
.search-box input::placeholder{
  color: #f2f2f2;
}
.search-box .go-icon{
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 60px;
  width: 70px;
  background: #ae492d;
  border: none;
  outline: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
.wrapper input[type="checkbox"]{
  display: none;
}
/* Dropdown Menu code start */
.content .links ul{
  position: absolute;
     background: #e9b465c7;
     border-radius: 25px;
     color: #f2f2f2;
  top: 80px;
  z-index: 7;
  opacity: 0;
  visibility: hidden;
}
.content .links li:hover > ul{
  top: 70px;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}
.content .links ul li a{
  display: block;
  width: 100%;
  line-height: 30px;
  border-radius: 0px!important;
}
.content .links ul ul{
  position: absolute;
  top: 0;
  right: calc(-100% + 8px);
}
.content .links ul li{
  position: relative;
  color: #e6e7e9;
}
.content .links ul li:hover ul{
  top: 0;
}
/* Responsive code start */
@media screen and (max-width: 1250px){
  .wrapper nav{
    max-width: 100%;
    padding: 0 20px;
  }
  nav .content .links{
    margin-left: 30px;
  }
  .content .links li a{
    padding: 8px 13px;
  }
  .wrapper .search-box{
    max-width: calc(100% - 100px);
  }
  .wrapper .search-box input{
    padding: 0 100px 0 15px;
  }
}
@media screen and (max-width: 900px){
  .wrapper .menu-icon{
    display: block;
    color: black;
  }
  .wrapper #show-menu:checked ~ .menu-icon i::before{
    content: "\f00d";
  }
  nav .content .links{
    display: block;
    position: fixed;
    background: #e9b465;
    height: 100%;
    width: 100%;
    top: 83px;
    left: -100%;
    margin-left: 0;
    max-width: 350px;
    overflow-y: auto;
    padding-bottom: 100px;
    transition: all 0.3s ease;
}
  
  nav #show-menu:checked ~ .content .links{
    left: 0%;
    z-index: 999;
  }
  .content .links li{
    margin: 15px 20px;
  }
  .content .links li a,
  .content .links li label{
    line-height: 40px;
    font-size: 20px;
    display: block;
    padding: 8px 18px;
    cursor: pointer;
  }
  .content .links li a.desktop-link{
    display: none;
  }
  /* dropdown responsive code start */
  .content .links ul,
  .content .links ul ul{
    position: static;
    opacity: 1;
    visibility: visible;
    background: none;
    max-height: 0px;
    overflow: hidden;
  }
  .content .links #show-features:checked ~ ul,
  .content .links #show-services:checked ~ ul,
  .content .links #show-items:checked ~ ul{
    max-height: 100vh;
  }
  .content .links ul li{
    margin: 7px 20px;
  }
  .content .links ul li a{
    font-size: 18px;
    line-height: 30px;
    border-radius: 5px!important;
  }
}
@media screen and (max-width: 400px){
  .wrapper nav{
    padding: 0 10px;
  }
  .content .logo a{
    font-size: 27px;
  }
  .wrapper .search-box{
    max-width: calc(100% - 70px);
  }
  .wrapper .search-box .go-icon{
    width: 30px;
    right: 0;
  }
  .wrapper .search-box input{
    padding-right: 30px;
  }
}


.main-logo>img{
  height: auto;
  width: 50%;
}
@media screen and (max-width: 820px){
.main-logo>img{
  display: none;

}
}
li{
  color: white;
}