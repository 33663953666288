
.card__container {
    padding-top: 108px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.card__container h1 {
    width: 90%;
    text-align: center;
    text-transform: capitalize;
    font-size: 2.2vw;
    font-weight: 600;
    color: black;
}
.card__container--inner {
    width: 90%;
    padding: 5px;
    display: block;
}
.card__container--inner--card {
    padding: 10px 0;
    max-width: 308px;
    color: #fff;
    font-size: 50px;
    text-align: center;
    margin-left: 10px;
    transition: all 0.1s ease;
}
.card__container--inner--card h2 {
    color: #3a3a3a;
    font-size: 1.3vw;
    font-weight: 600;
    padding-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    line-height: 1;
}
.card__container--inner--card p {
    font-size: 1vw;
    font-weight: 400;
    color: #3a3a3a;
    text-align: left;
    padding-left: 8px;
    line-height: 0;
}
.card__container--inner--card p span {
    color: #3a3a3a;
    font-size: 1.2vw;
    font-weight: 600;
    padding-left: 8px;
}
.card__container--inner--card img {
    width: 100%;
    height: 220px;
    border-radius: 1vw;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 10px;
}
.card__container--inner--card:hover {
    transform: scale(1.05);
    cursor: pointer;
}
.card__container--inner--card--date_time {
    display: flex;
    align-items: center;
    margin-top: 5px;
}
.card__container--inner--card--date_time img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}
.card__container--inner--card--date_time p {
    color: #0a9e88;
    font-weight: bold;
}

.slick-next, .slick-prev {
    padding: 15px;
    width: 3vw;
    height: 3vw;
    cursor: pointer;
    border: none;
    border-radius: 5vw;
    background-color: #fff;
    z-index: 99 !important;
    box-shadow: white 0px 0px 20px 5px;
    top: 40%;
}
.slick-next:hover, .slick-prev:hover {
    background-color: #fff;
}
