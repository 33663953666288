
.prediabetes{

   flex-direction: row;
  
   padding: 5px;
   gap: 12px;
   border-radius: 15px;
 
   padding-left: 1px;
   background-repeat: no-repeat;
   margin-left: 55px;
   background-attachment: fixed;
   background-position: 1px;
   background-size: cover;
   margin-left: 28px;
   margin-right: 55px;



 
}
.text> h2{
   font-family: 'Lato', sans-serif;
   color:#2fa2db;
   font-size: 36px;
   font-size: clamp(1.4rem, 3vw, 7rem);
}
.text> p{
   font-family: 'Andika', sans-serif;
    padding: 3px;
    color:#414141;
    font-size: 16px;
    font-size: clamp(0.6rem, 1.3vw, 2rem);

 }
 h3{
   font-family: 'Lato', sans-serif;
   color: #2fa2db;
   font-size: 36px;
   font-size: clamp(1.1rem, 1.9vw, 2rem);
   
 }

 .prediabetes>img{
   margin-top: 137px;
   width: 38%;
   height: 61VH;
   border-radius: 9px;
   
   

 }

.text{
   
   background: transparent linear-gradient(95deg,#f7f7f7 0,#f7f4f4 100%) 0 0 no-repeat padding-box;
   border-radius: 7px;
   margin-left: -88px;
   z-index: 1;
   margin-top: 192px;
   color: white;
 
}
 @media  (min-width:768px){
    .prediabetes {
        display: flex;
        width: auto;
    }
    .prediabetes img{

    }
    .slider-bottom{
      margin-top: 0px;
   
  }
    }
    
 
 @media screen and (max-width: 800px){
   .prediabetes {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    width: auto;
    margin-bottom: -326px;
   }
   .text{
    
   margin-left: 0px;

   }
   .prediabetes img{
     width: 100%;
     height: auto;
   }
   .slider-bottom{
    margin-top: 288px;
    margin-bottom: 50px;
    }
}



    
